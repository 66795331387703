import React, { Fragment } from 'react'

import { currencyFormatter, areaFormatter } from '@utils'

import Title from '@components/title'

const ApartmentHeader = ({ nimi, pintaAla, vuokra }) => {
  return (
    <Fragment>
      <Title heading="h2" noUnderline color="dark-gray" className="mb4">
        {nimi}
      </Title>

      <div className="flex mb3-sm mb5 pb3-sm pb4 bb b--light-gray josefin">
        <Item text={areaFormatter(pintaAla)} label="Pinta-ala:" />
        <Item text={currencyFormatter(vuokra)} label="Vuokra:" />
      </div>
    </Fragment>
  )
}

export default ApartmentHeader

const Item = ({ text, label }) => {
  return (
    <div className="w-50">
      <div className="navy fw6 f6-sm f5-m f4-l lh-solid">{text}</div>
      <div className="fw6 f11-sm f10-m f9-l">{label}</div>
    </div>
  )
}
