import React from 'react'

import Title from '@components/title'
import ListWrapper from '@components/list-wrapper'

const ApartmentMap = ({ location }) => {
  if (!location) return null

  const { latitude, longitude } = location

  const apiKey = 'AIzaSyCCXVh0M2zULs_4WQ53Ixaqh6IebB1afJc'
  return (
    <ListWrapper>
      <Title heading="h2" className="mb6">
        Sijainti
      </Title>
      <iframe
        title="location"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        src={`https://www.google.com/maps/embed/v1/place?q=${latitude},${longitude}&key=${apiKey}&zoom=10`}
        allowFullScreen
      />
    </ListWrapper>
  )
}

export default ApartmentMap
