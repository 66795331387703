import React from 'react'

import Title from '@components/title'
import ListWrapper from '@components/list-wrapper'

const ApartmentDescriptionLong = ({ vapaaKuvaus }) => {
  if (!vapaaKuvaus) return null
  return (
    <ListWrapper>
      <Title heading="h2" className="mb5">
        Vapaa kuvaus
      </Title>
      <p className="mv0">{vapaaKuvaus}</p>
    </ListWrapper>
  )
}

export default ApartmentDescriptionLong
