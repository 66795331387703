import React, { Fragment } from 'react'

import { dateFormatter, roomFormatter } from '@utils'

const ApartmentSpecs = ({
  kylpyhuoneet,
  makuuhuoneet,
  huoneet,
  talonTyyppi,
  rakennusvuosi,
  kerros,
  asunnonTyyppi,
  muuttovalmis
}) => {
  const listClasses =
    'list josefin flex flex-wrap bb b--light-gray pb3-sm pb5 mb3-sm mb5'
  return (
    <Fragment>
      <ul className={listClasses}>
        <Item label="Huoneet" text={huoneet} />
        <Item label="Makuuhuoneet" text={makuuhuoneet} />
        <Item label="Kylpyhuoneet" text={kylpyhuoneet} />
      </ul>
      <ul className={listClasses}>
        <Item label="Talon Tyyppi" text={talonTyyppi} />
        <Item label="Rakennusvuosi" text={rakennusvuosi} />
        <Item label="Kerros" text={kerros} />
      </ul>
      <ul className={listClasses}>
        <Item label="Asunnon Tyyppi" text={roomFormatter(asunnonTyyppi)} />
        <Item label="Muuttovalmis" text={dateFormatter(muuttovalmis)} />
      </ul>
    </Fragment>
  )
}

export default ApartmentSpecs

const Item = ({ text, label }) => {
  return (
    <li className="lh-solid w-third items-separator relative">
      <div className="silver f12-sm f11-m f10 mb2">{label}</div>
      <div className="f9-sm f8-m f7-l">{text}</div>
    </li>
  )
}
