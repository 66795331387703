import React from 'react'

import { handleYesNo } from '@utils'
import List from '@components/list'
import ListWrapper from '@components/list-wrapper'

const ApartmentHousing = ({
  vuokraanSisaltyyLaajakaista,
  vuokraanSisaltyyVesi
}) => {
  const items = [
    {
      label: 'Laajakaista',
      text: handleYesNo(vuokraanSisaltyyLaajakaista)
    },
    {
      label: 'Vesi',
      text: handleYesNo(vuokraanSisaltyyVesi)
    }
  ]
  return (
    <ListWrapper>
      <List title="Vuokraan sisältyy" items={items} width="220px" />
    </ListWrapper>
  )
}

export default ApartmentHousing
