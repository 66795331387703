import React, { Fragment } from 'react'

import Title from '@components/title'

const List = ({ title, items, width }) => {
  return (
    <Fragment>
      <Title heading="h2" className="mb5">
        {title}
      </Title>

      <ul className="list f10-sm f9-m f8-l josefin" style={{ maxWidth: width }}>
        {items.map((item, i) => (
          <li className="mb3-sm mb4 flex" key={i}>
            <span className="gray-blue w-60">{item.label}:</span>
            <span className="silver w-40">{item.text}</span>
          </li>
        ))}
      </ul>
    </Fragment>
  )
}

export default List
