import React, { Fragment, useState } from 'react'
import SwiperCore, { Thumbs, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Image from '@components/image'
import './styles.css'

SwiperCore.use([Thumbs, Navigation])

const ApartmentImages = ({ pohjapiirros, asunnonKuvat }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const plan = [
    <SwiperSlide key="pohjapiirros">
      <Image image={pohjapiirros} isAbsolute contain />
    </SwiperSlide>
  ]
  const planThumb = [
    <SwiperSlide key="pohjapiirros">
      <Image image={pohjapiirros} isAbsolute />
    </SwiperSlide>
  ]

  const pictures = asunnonKuvat.map((kuvat, i) => {
    return (
      <SwiperSlide key={i}>
        <Image image={kuvat} isAbsolute contain />
      </SwiperSlide>
    )
  })
  const picturesThumb = asunnonKuvat.map((kuvat, i) => {
    return (
      <SwiperSlide key={i}>
        <Image image={kuvat} isAbsolute />
      </SwiperSlide>
    )
  })

  const slides = pictures.concat(plan)
  const thumbs = picturesThumb.concat(planThumb)

  return (
    <Fragment>
      <Swiper
        thumbs={{ swiper: thumbsSwiper }}
        spaceBetween={10}
        navigation
        loop
      >
        {slides}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={5}
        freeMode
        watchSlidesVisibility
        watchSlidesProgress
      >
        {thumbs}
      </Swiper>
    </Fragment>
  )
}

export default ApartmentImages
