import React from 'react'

const ListWrapper = ({ children }) => {
  return (
    <div className="mb4-sm mb5-m mb6-l pb4-sm pb5-m pb6-l bb b--light-gray">
      {children}
    </div>
  )
}

export default ListWrapper
