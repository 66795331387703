import React, { useEffect, useState } from 'react'
import { request } from 'graphql-request'
import classNames from 'classnames'

import Layout from '@components/layout'
import ContactForm from '@components/contact-form'

import ApartmentHeader from '@page/asunto/apartment-header'
import ApartmentSpecs from '@page/asunto/apartment-specs'
import ApartmentDescriptionList from '@page/asunto/apartment-description-list'
import ApartmentDescriptionLong from '@page/asunto/apartment-description-long'
import ApartmentMap from '@page/asunto/apartment-map'
import ApartmentImages from '@page/asunto/apartment-images'
import ApartmentHousing from '@page/asunto/apartment-housing'

const Asunto = props => {
  const { id, uri } = props
  const [asunto, setAsunto] = useState({})

  useEffect(() => {
    const fetchAsunto = async () => {
      const { asunnot } = await request(
        'https://api-eu-central-1.graphcms.com/v2/ck38r0dyu41ue01cwht9k4nf0/master',
        `
          {
            asunnot(where: {id: "${id}"}) {
              nimi,
              pohjapiirros {
                handle
                height
                width
              }
              talonTyyppi
              rakennusvuosi
              kerros
              asunnonTyyppi
              pintaAla
              vuokra
              muuttovalmis
              hissi
              parveke
              laajakaista
              vuokraanSisaltyyLaajakaista
              vuokraanSisaltyyVesi
              pyoravarasto
              sijainti {
                latitude
                longitude
              }
              vapaaKuvaus
              asunnonKuvat {
                handle
                height
                width
              }
              vuokrattu
              uusi
              kylpyhuoneet
              makuuhuoneet
              huoneet
            }
          }
        `
      )

      setAsunto(asunnot[0])
    }

    fetchAsunto()
  }, [])

  const {
    nimi,
    pohjapiirros,
    talonTyyppi,
    rakennusvuosi,
    kerros,
    asunnonTyyppi,
    pintaAla,
    vuokra,
    muuttovalmis,
    hissi,
    parveke,
    laajakaista,
    vuokraanSisaltyyLaajakaista,
    vuokraanSisaltyyVesi,
    pyoravarasto,
    sijainti,
    vapaaKuvaus,
    asunnonKuvat = [],
    vuokrattu,
    uusi,
    kylpyhuoneet,
    makuuhuoneet,
    huoneet
  } = asunto

  return (
    <Layout currentLocation={uri}>
      <section className="section--bg pv4-sm pv5">
        <div className="container">
          <div className="flex flex-wrap mb5-sm mb6">
            <div className="w-100 order-2 order-1-l w-60-l pr6-l">
              <ApartmentImages
                asunnonKuvat={asunnonKuvat}
                pohjapiirros={pohjapiirros}
              />
            </div>

            <div className="w-100 order-1 order-2-l w-40-l">
              <ApartmentHeader
                nimi={nimi}
                pintaAla={pintaAla}
                vuokra={vuokra}
              />

              <ApartmentSpecs
                kylpyhuoneet={kylpyhuoneet}
                makuuhuoneet={makuuhuoneet}
                huoneet={huoneet}
                talonTyyppi={talonTyyppi}
                rakennusvuosi={rakennusvuosi}
                kerros={kerros}
                asunnonTyyppi={asunnonTyyppi}
                muuttovalmis={muuttovalmis}
              />
            </div>
          </div>

          <div className="flex flex-wrap">
            <div className="w-100-sm w-50-m w-60-l">
              <ApartmentDescriptionLong vapaaKuvaus={vapaaKuvaus} />

              <ApartmentDescriptionList
                hissi={hissi}
                parveke={parveke}
                laajakaista={laajakaista}
                pyoravarasto={pyoravarasto}
                vuokrattu={vuokrattu}
                uusi={uusi}
              />

              <ApartmentHousing
                vuokraanSisaltyyLaajakaista={vuokraanSisaltyyLaajakaista}
                vuokraanSisaltyyVesi={vuokraanSisaltyyVesi}
              />

              <ApartmentMap location={sijainti} />
            </div>

            <div
              className={classNames({
                'pl5-m pl5-l pl0-sm w-100-sm w-50-m w-40-l': true,
                'flex flex-column items-start-sm items-end': true
              })}
            >
              <ContactForm inContainer width="360px" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Asunto
