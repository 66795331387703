import React from 'react'

import { handleYesNo } from '@utils'
import List from '@components/list'
import ListWrapper from '@components/list-wrapper'

const ApartmentDescriptionList = ({
  hissi,
  parveke,
  laajakaista,
  pyoravarasto,
  vuokrattu,
  uusi
}) => {
  const items = [
    {
      label: 'Hissi',
      text: handleYesNo(hissi)
    },
    {
      label: 'Parveke',
      text: handleYesNo(parveke)
    },
    {
      label: 'Laajakaista',
      text: handleYesNo(laajakaista)
    },
    {
      label: 'Pyörävarasto',
      text: handleYesNo(pyoravarasto)
    },
    {
      label: 'Vuokrattu',
      text: handleYesNo(vuokrattu)
    },
    {
      label: 'Uusi',
      text: handleYesNo(uusi)
    }
  ]
  return (
    <ListWrapper>
      <List title="Kuvaus" items={items} width="240px" />
    </ListWrapper>
  )
}

export default ApartmentDescriptionList
